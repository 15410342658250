import React, { ReactNode, useState, useEffect } from 'react';
import "./Home.css";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import Spline from '@splinetool/react-spline';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


interface AnimatedBlockProps {
  children: ReactNode;
  delay?: number;
}

const AnimatedBlock: React.FC<AnimatedBlockProps> = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};


const Home = () => {


  const { t } = useTranslation(); // Инициализация функции для перевода
  const [years, setYears] = useState(0); // Состояние для лет
  const [months, setMonths] = useState(0); // Состояние для месяцев

  // Функция для изменения языка
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };


  useEffect(() => {
    // Функция для вычисления лет и месяцев
    const calculateYearsAndMonths = () => {
      const startDate = new Date(2022, 7, 1); // Начальная дата (1 августа 2022 года)
      const currentDate = new Date(); // Текущая дата

      let yearsDiff = currentDate.getFullYear() - startDate.getFullYear();
      let monthsDiff = currentDate.getMonth() - startDate.getMonth();

      if (monthsDiff < 0) {
        yearsDiff--;
        monthsDiff += 12;
      }

      setYears(yearsDiff);
      setMonths(monthsDiff);
    };

    calculateYearsAndMonths();
  }, []);


  return (<div className="home_page_block">
    <div className="home_page_block-info">
      <AnimatedBlock>
        <div id="about" className="home_page_title">About</div>
        <div className="home_page_title_2">Gennadiy Manzhos - personal website</div>
      </AnimatedBlock>
      <div className="home_page_title_intro">
        <div className="home_page_title_intro-item">
          <div className="home_page_title_desc">
            {t('home_page_title_desc')}
          </div>
          <AnimatedBlock delay={0.3}>
            <div className='home_page_presentation'>
              <br></br>
              <br></br>
              <a href="./files/python_in_medicine.pdf">
                <div className='home_page_presentation_a'>Presentation Python in Medicine</div>
              </a>
              <a href="./files/CV.pdf">
                <div className='home_page_CV'>CV</div>
              </a>
              <br></br>
              <br></br>
            </div>
          </AnimatedBlock>
        </div>
        <div className="home_page_title_intro-item">
          <AnimatedBlock delay={0.2}>
            <img className="home_page_photo" src="photo.jpg"
              alt="photo" />
          </AnimatedBlock>
          <AnimatedBlock delay={0.4}>
            <div className="home_page_title_name">{t('Gennadiy_Manzhos')}</div>
            <div className="home_page_title_job">Data Scientist & ML Engineer</div>
          </AnimatedBlock>
        </div>
      </div>
      <hr className="line_break2" />
      <div className="home_page_category_content">
        <div className="home_page_category_title">{t('home_page_category_title')}</div>
        <div className="home_page_category_info">{t('home_page_category_info')}
          <ul className="home_page_list_disciplines">
            <li>{t("ml_course")}</li>
            <li>{t("statistics_course")}</li>
            <li>{t("python_course")}</li>
            <li>{t("sql_course")}</li>
          </ul>
          {t("teach_disciplines_to")}</div>
        <div className='exp_blocks'>
          <AnimatedBlock delay={0.6}>
            <div className='exp_block_1_back'>
              <div className='exp_block_1'>
                <b><h1>3</h1>years</b> of experience in <b>ai field</b>
              </div>
            </div>
          </AnimatedBlock>
          <AnimatedBlock delay={0.8}>
            <div className='exp_block_2_back'>
              <div className='exp_block_2'>
                help for your business to integration any <b>ai-services</b>
              </div>
            </div>
          </AnimatedBlock>
        </div>
        <br />
        <br />
        <div className="home_page_category_title">{t('home_page_category_keyskills')}</div>
        <ul className="home_page_list_disciplines">
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_1')}</li>
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_2')}</li>
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_3')}</li>
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_4')}</li>
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_5')}</li>
          <li className='home_page_list_disciplines_item'>{t('home_page_category_info_keyskill_6')}</li>
        </ul>
        {/* <div className="keyskills_heads">
          <AnimatedBlock delay={0.3}>
            <div className="keyskills_head">ai frameworks</div>
          </AnimatedBlock>
          <AnimatedBlock delay={0.7}>
            <div className="keyskills_head">develop</div>
          </AnimatedBlock>
          <AnimatedBlock delay={1.1}>
            <div className="keyskills_head">data visualization</div>
          </AnimatedBlock>
          <AnimatedBlock delay={1.5}>
            <div className="keyskills_head">else</div>
          </AnimatedBlock>
        </div>
        <div className="keyskills_items">
          <div className="keyskills_items_block">
            <AnimatedBlock delay={0.4}>
              <div className="keyskills_text">NLP, Transformers</div>
              <div className="keyskills_text">MAB, GAN, RAG</div>
              <div className="keyskills_text">CV, RL, Q-Learning</div>
            </AnimatedBlock>
          </div>
          <div className="keyskills_items_block">
            <AnimatedBlock delay={0.6}>
              <div className="keyskills_text">auto ml libraries</div>
              <div className="keyskills_text">python & javascript</div>
              <div className="keyskills_text">pytorch, pycaret</div>
              <div className="keyskills_text">django & flask</div>
              <div className="keyskills_text">sql, postgres</div>
              <div className="keyskills_text">HTML & CSS</div>
            </AnimatedBlock>
          </div>
          <div className="keyskills_items_block">
            <AnimatedBlock delay={0.8}>
              <div className="keyskills_text">plotly</div>
              <div className="keyskills_text">dash</div>
              <div className="keyskills_text">matplotlib</div>
              <div className="keyskills_text">seaborn</div>
            </AnimatedBlock>
          </div>
          <div className="keyskills_items_block">
            <AnimatedBlock delay={1.0}>
              <div className="keyskills_text">docker, linux</div>
              <div className="keyskills_text">cmd, powershell</div>
              <div className="keyskills_text">airflow, mlflow</div>
              <div className="keyskills_text">fastAPI</div>
            </AnimatedBlock>
          </div>
        </div>*/}
        <hr className="line_break2" />
        <br />

        {/* <div className="home_page_category_title">{t("How I can help you")}</div>
        <div className="home_page_category_info">
          <div className="container_couch">
            <div className="container_item_couch">
              <Spline className='spline_scene' scene="https://prod.spline.design/V1L5AMHj1PgBixHF/scene.splinecode" />
              <li className="uslugi_li">{t("Private")}</li>
            </div>
            <div className="container_item_couch">
              <Spline className='spline_scene' scene="https://prod.spline.design/fA8sMPgWHvortuSX/scene.splinecode" />
              <li className="uslugi_li">{t("Technical")}</li>
            </div>
            <div className="container_item_couch">
              <Spline className='spline_scene' scene="https://prod.spline.design/lCUb2GcpAwwzrUAb/scene.splinecode" />
              <li className="uslugi_li">{t("Automatic")}</li>
            </div>
          </div>
        </div> */}
        <AnimatedBlock delay={1.0}>
          <div className="conf_photo_container">
            <img className="conf_photo" src="conf_photo.webp"
              alt="conf_photo" />
          </div>
        </AnimatedBlock>
        <br />
        <br />
        <AnimatedBlock delay={0.3}>
          <a className="home_page_contact_links1" href="mailto:contact@manzhosgennadiy.ru">{t("contacts")}</a>
        </AnimatedBlock></div>

    </div >

  </div >
  );
};

export default Home;
